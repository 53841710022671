import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
// import { AddInstructorProp } from "./interface";
import { toast } from "react-toastify";
import { ErrorMessage, FieldArray, useFormik } from "formik";
import { Button, Form, Modal } from "react-bootstrap";
import users from "../../../assets/images/users.png"
import offer_book from "../../../assets/images/offer_book.png"
import BookVector from "../../../assets/images/BookVector.png"
import JoinVector from "../../../assets/images/JoinVector.png"
import CreateVector from "../../../assets/images/CreateVector.png"

import cpy from "../../../assets/images/cpy.png"
import shr from "../../../assets/images/shr.png"
// import { addCourse, course, editCourse } from "../api";
import styles from "../../instructor/styles/instructor.module.css";


import commonstyles from "../../instructor/styles/common.module.css";
//import commonstyles from "../../instructor/styles/common.module.css";
// import { CourseDetailsSchema } from "../validations";
//import { INSTRUCTOR } from "../../../utils/instructor";
//import { useAppSelector } from "../../../redux/hooks";
// import {
// 	DepartmentInterface,
// 	Instructor,
// 	UniversityInterface,
// } from "../../instructor/api/interface";
// import {
// 	getAllInstructorUniversity,
// 	getAllUniversities,
// } from "../../instructor/api";
// // import { AddCourseParam } from "../api/interface";
// import { PaginationInterface } from "../../../components/Pagination/interfaces";
// import { COURSES } from "../../../utils/courses";
// import { MESSAGES } from "../../../utils/messages";
// import { YUP } from "../../../utils/validations";
import { AddInstructorProp } from "../../course/modals/interface";
import { addCoupon, coupon, editCoupon } from "../api";
import { CouponDetails } from "../api/interface";
import { useAppSelector } from "../../../redux/hooks";
import { INSTRUCTOR } from "../../../utils/instructor";
import { COUPONS } from "../../../utils/coupons";
import { MESSAGES } from "../../../utils/messages";
import { CouponDetailsSchema } from "../validations";
import {  UniversityInterface } from "../../instructor/api/interface";
import { getAllUniversities } from "../../instructor/api";
import { COURSES } from "../../../utils/courses";
import { courseListWithoutPagination } from "../../student/api";
import { CourseDeatails } from "../../course/api/interface";
import { CouponFormik, SelectedUni } from "./interface";
import { uploadImage } from "../../common/utils";
import { courseList } from "../../course/api";

const AddorEdit = ({ showModal, handleClose }: AddInstructorProp) => {
	const selectedCoupon = useAppSelector((state) => state.couponReducer);
	const [universities, setUniversities] = useState<UniversityInterface[]>([]);
	// const [department, setDepartment] = useState<DepartmentInterface[]>();
	const [course, setCourse] = useState<CourseDeatails[]>([]);
	const [selectedCourses, setSelectedCourses] = useState<number[]>([]);
	const [bannerImage, setBannerImage] = useState<any>(null);
	const [loadingCourse,setLoadingCourse]=useState(false)
	const [customised,setCustomised]=useState(false)

	// const [selectedUniversity, setSelectedUniversity] = useState<any>([]);
	const [selectedCourse, setSelectedCourse] = useState<number >(0);

	const [loading, setLoading]=useState<boolean>(false)
	const [selectedUniversities, setSelectedUniversities] = useState({});


	const handleCourseCheckboxClick = (courseId: number) => {
		couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.SELECTED_COURSE, courseId);

	  setSelectedCourse(courseId);
	};

	const [selectedDepartments, setSelectedDepartments] = useState<number[]>([]);
	// const [selectedUniversities, setSelectedUniversities] = useState<number[]>([]);


	useEffect(() => {
		getAllUniversities().then((res) => {
			setUniversities(res?.data);
		});

	}, [showModal]);
	const calculateUniIndex=(uni?:any,id?:any)=>{
		// console.log(uni,id,"hjjjj")
		let a=0
		couponsDetailsFormik.values.selectedUniversities.map((obj:any,ind:any)=>{
			if(Number(obj.university_id)===Number(id))
			{
				a=ind
			}
		})
		return a
		
	}

	
const handleUniversityChange=(e:any,universityId:any)=>{
	console.log(e.value,"valueee")


	
	
	

	if(e.target.checked)
	{
		couponsDetailsFormik.setFieldValue("segments",{...couponsDetailsFormik.values.segments,[universityId]:[
			{ min: '',
				max: '',
				discount: '',
				discount_type: '2',}
		]})
	}
	else{
		const segment=couponsDetailsFormik.values.segments;
		//@ts-ignore
		delete segment[universityId]
		couponsDetailsFormik.setFieldValue("segments",segment)
	}
}

const addConfis=(universityId:any)=>{
	couponsDetailsFormik.setFieldValue("segments",{...couponsDetailsFormik.values.segments,[universityId]:[
		//@ts-ignore
		...couponsDetailsFormik.values.segments[universityId],
		{ min: '',
			max: '',
			discount: '',
			discount_type: '2',}
	]})

}
	// const addDepartments = (id: Number) => {
	// 	if (id === 0) {
	// 		setDepartment([]);
	// 		return;
	// 	}
	// 	const selectedUniversity = universities.filter(
	// 		(uni: UniversityInterface) => {
	// 			return uni.id === id;
	// 		}
	// 	);
	// 	setDepartment(selectedUniversity[0]?.universityDepartments);
	// };
	// const handleUniversityChange = (e: any) => {
	// 	couponsDetailsFormik.handleChange(e);
	// 	addDepartments(Number(e.target.value));
		
	// };

	// const handleCheckboxClick = (e: React.ChangeEvent<HTMLInputElement>) => {
	// 	couponsDetailsFormik.setFieldError(COURSES.FORMIK.DEPARTMENT, "");
	// 	if (e.target.checked === true) {
	// 		setSelectedDepartments((prevState)=>[...prevState, Number(e.target.value)]);
	// 	} else {
	// 		const selectedDepartmentsCopy = selectedDepartments;
	// 		const selectedDepartmentsList = selectedDepartmentsCopy.filter((num) => {
	// 			return num !== Number(e.target.value);
	// 		});
	// 		setSelectedDepartments(selectedDepartmentsList);
	// 	}
		
	// };

	const  handleUniversityCheckboxClick = (e:React.ChangeEvent<HTMLInputElement>, id:number) => {
		const array:SelectedUni[]=couponsDetailsFormik.values.selectedUniversities
		console.log(array, id)
		
		if(e.target.checked)
		{
			setLoadingCourse(true)
			courseList(undefined,id,undefined,true).then((res)=>{
				setLoadingCourse(false)
				couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.SELECTED_UNIVERSITIES, [...array, { university_id: id, discount: "", discount_type: "2",allCourses:res.data,courses:[] }])
			})
			

		}
		else{
				
				  let arr=array.filter((u)=>{
				return u.university_id!==id
				  })
				  couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.SELECTED_UNIVERSITIES, arr)

		}

			  };
			  const addMore = ( id:number) => {
				const array:SelectedUni[]=couponsDetailsFormik.values.selectedUniversities
				console.log(array, id) 

couponsDetailsFormik.values.selectedUniversities.map((obj)=>{
	if(obj.university_id===id)
	{
		couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.SELECTED_UNIVERSITIES, [...array, { university_id: id, discount: "", discount_type: "2",allCourses:obj.allCourses,courses:[] }])
return
	}
})
					// setLoadingCourse(true)
					// courseList(undefined,id,undefined,true).then((res)=>{
					// 	setLoadingCourse(false)
					// 	couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.SELECTED_UNIVERSITIES, [...array, { university_id: id, discount: "", discount_type: "2",allCourses:res.data,courses:[] }])
					// })

					  };
			  const handleCourseCheckbox=(e:any,university_id:any,course_id:any,ind:number)=>{
				const array=searchUniversity(university_id,"courses",ind);
				// const index=couponsDetailsFormik.values.selectedUniversities.findIndex(
				// 	(u) => u.university_id === university_id
				//   )
				 const obj= couponsDetailsFormik.values.selectedUniversities[ind]


				if(e.target.checked)
					{
						const na:any=[]
						couponsDetailsFormik.values.selectedUniversities.map((uni,index)=>{
							if(index===ind)
							{
								obj.courses=[...array,course_id]
								na.push(obj);

							}
							else{
								na.push(uni)
							}
						})
						
						couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.SELECTED_UNIVERSITIES, na)	
			
					}
					else{
							
						const na:any=[]
						couponsDetailsFormik.values.selectedUniversities.map((uni,index)=>{
							if(index===ind)
							{
								const index = array.indexOf(course_id);
  if (index > -1) {
    array.splice(index, 1);
  }
								obj.courses=[...array]
na.push(obj);
							}
							else{
								na.push(uni)
							}
						})
						
						couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.SELECTED_UNIVERSITIES, na)	
			
					}

			  }
	
	// const handleUniversityCheckboxClick = (e: React.ChangeEvent<HTMLInputElement>) => {
	// 	couponsDetailsFormik.setFieldError("university", "");
		
	// 	if (e.target.checked === true) {
	// 		setSelectedUniversities((prevState)=>[...prevState, Number(e.target.value)]);
	// 		setSelectedUniversity((prevState:any)=>[...prevState, {university_id:Number(e.target.value),discount_type:1}])
	// 	} else {
	// 		const selectedUniversityCopy = selectedUniversities;
	// 		const selectedUniversityList = selectedUniversityCopy.filter((num) => {
	// 			return num !== Number(e.target.value);
	// 		});
	// 		setSelectedUniversities(selectedUniversityList);
	// 		const selectedUniversityCopyc = selectedUniversity;
	// 		const selectedUniversityListc = selectedUniversityCopyc.filter((num:any) => {
	// 			return num.university_id !== Number(e.target.value);
	// 		});
	// 		setSelectedUniversity(selectedUniversityListc);
	// 	}
		
		
	// };
	// const handlePriceChange=(id:number,e:any)=>{
	// 	let updatedArray=selectedUniversity;
	// 	updatedArray=selectedUniversity.map((obj:any)=>{
	// 		if(obj.university_id===id)
	// 			{
	// 				return {
	// 					...obj,discount:e.target.value
	// 				}
	// 			}
	// 			else{
	// 				return {...obj}
	// 			}
	// 	})
	// 	setSelectedUniversity(updatedArray)

	// }
	// const handleUnitChange=(id:number,e:any)=>{
	// 	let updatedArray=selectedUniversity;
	// 	updatedArray=selectedUniversity.map((obj:any)=>{
	// 		if(obj.university_id===id)
	// 			{
	// 				return {
	// 					...obj,discount_type:e.target.value
	// 				}
	// 			}
	// 			else{
	// 				return {...obj}
	// 			}
	// 	})
	// 	setSelectedUniversity(updatedArray)


	// }
	type SelectedUniKeys = keyof SelectedUni;
	const searchUniversity=(id:number, param:SelectedUniKeys,ind:number):any=>{
		// const index=couponsDetailsFormik.values.selectedUniversities.findIndex(
		// 	(u) => u.university_id === id
		//   )
		//   console.log(couponsDetailsFormik.values.selectedUniversities[index][param],"check")
		  
		// return couponsDetailsFormik.values.selectedUniversities[index][param]
		return couponsDetailsFormik.values.selectedUniversities[ind][param]

		
	}

	const fetchCourses = async (university_id:any) => {
		const response = await courseList(undefined,university_id,undefined);
	   
		return await response.data;
	};
	
	const fetchAllCourses = async (universities:any) => {
		const promises = universities.map((uni:any) => fetchCourses(uni.university_id));
		return await Promise.all(promises)
	};


	


	useEffect(() => {
		if (selectedCoupon.editOrAdd === INSTRUCTOR.REDUX.EDIT) {
			coupon(String(selectedCoupon.id),{for_detail:true}).then((res) => {
				
				const {data:{backgroud_color, text, text_color, type, button_color, code, courses_count,  expiry_date, max_members,  departments, courses, universities,segments,duration,duration_type,discount_type,discount_amount,gradient_to,gradient_from,gradient_top,gradient_bottom,gradient_type,text_size,limit}}=res;
				console.log(type.toLowerCase())
				if(type.toLowerCase()===COUPONS.TYPES.GROUP_COUPON)
				{
					couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.COUPON_TYPE, 1)
					couponsDetailsFormik.setFieldValue("segments", segments)
					couponsDetailsFormik.setFieldValue("duration", duration)
					couponsDetailsFormik.setFieldValue("duration_type", duration_type)

				}
				else if(type.toLowerCase()===COUPONS.TYPES.COURSE_DISCOUNT)
				{
					const cloneUni=[...universities]
					couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.COUPON_TYPE, 2)

// 					universities?.map((uni,i)=>{
// 						cloneUni[i]=uni
// 						courseList(undefined,uni.university_id,undefined).then((res)=>{
// uni.allCourses=res.data
// const modifiedCourses=	uni?.course?.map((cou:any)=>{
// 	return cou.course_id


// })
// uni.courses=modifiedCourses

// 						})
					

// 					})
// 					console.log(universities,"iuiuiuiu")


// const fetchAllCourses= async(universities:any)=>{
// 	const cloneUni:any=[]
// 	universities?.map(async(uni:any,i:any)=>{
// 		cloneUni[i]=uni
// const cl=await courseList(undefined,uni.university_id,undefined);
// const modifiedCourses=	cl.data.map((cou:any)=>{
// 		return cou.id
	
	
// 	 })
// 	 cloneUni[i].allCourses=modifiedCourses
	 
	


// 	})
// 	return cloneUni

// }

// fetchAllCourses(universities).then((res)=>{
// 	couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.SELECTED_UNIVERSITIES, res)
// 	console.log(res,"ressd,nfsdjbfkwdf")

// })

let cl=universities
universities.map((cou:any,ind:any)=>{
	const modifiedCourse=cou.course.map((it:any)=>{
		return it.course_id
	})
	cl[ind].courses=modifiedCourse



})
	fetchAllCourses(universities).then((res)=>{
		res.map((val,ind)=>{
			cl[ind].allCourses=val
		
			couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.SELECTED_UNIVERSITIES, cl)
			console.log(cl,"sdds")
		})
		

	})				

				}
				
				else if(type.toLowerCase()===COUPONS.TYPES.SPECIAL_PACKAGE)
				{
					couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.COUPON_TYPE, 3)
					// couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.SELECTED_UNIVERSITIES, universities)
					let cl=universities
universities.map((cou:any,ind:any)=>{
	const modifiedCourse=cou.course.map((it:any)=>{
		return it.id 
	})
	cl[ind].courses=modifiedCourse



})
	fetchAllCourses(universities).then((res)=>{
		res.map((val,ind)=>{
			cl[ind].allCourses=val
		
			couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.SELECTED_UNIVERSITIES, cl)
			console.log(cl,"sdds")
		})
		

	})	

				}
				else
				{
					couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.COUPON_TYPE, 6)
					couponsDetailsFormik.setFieldValue("discount_type",discount_type==="Percentage"?2:1)
					couponsDetailsFormik.setFieldValue("discount_amount",discount_amount)
					couponsDetailsFormik.setFieldValue("slimit",limit?limit:'')


				
				}
				// addDepartments(university_id)
				// const depList:number[]=[];
				// departments.map((dep)=>{
				// 	depList.push(dep.department_id)
				// })
				
				// couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.DEPARTMENTS, depList)
				// setSelectedDepartments(depList)
				couponsDetailsFormik.setFieldValue("text_size", text_size)
				couponsDetailsFormik.setFieldValue("gradient_type", gradient_type)



				couponsDetailsFormik.setFieldValue("gradient_to", gradient_to)
				couponsDetailsFormik.setFieldValue("gradient_from", gradient_from)

				couponsDetailsFormik.setFieldValue("gradient_top", gradient_top)

				couponsDetailsFormik.setFieldValue("gradient_bottom", gradient_bottom)


				couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.BACKGROUND_COLOR, backgroud_color)

				couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.TEXT_COLOR, text_color)

				couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.BUTTON_COLOR, button_color)

				couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.CODE, code)
				if(type!=="Simple Coupon")
			{
			
				couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.DATE, expiry_date)
			}

				
				couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.LIMIT, courses_count?courses_count:0)

				couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.TEXT, text?text:"")
			
				
				couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.MEMBERS, max_members)
				
				
			});
		} else {
			couponsDetailsFormik.resetForm();
			
		}
	}, [selectedCoupon.editOrAdd, showModal]);
	

	const couponsDetailsFormik = useFormik<CouponFormik>({
		

		initialValues: {
			couponType:1,
			backgroundColor:"#000000",
			textColor:"#000000",
			buttonColor:"#000000",
			code:"",
			date:"",
			text:"",
			limit:0,
			selectedUniversities: [],
			courses:[],
			selectedCourse:0,
			bannerImage:"",
			gradient_from:"#094E85",
			gradient_to:"#094E85",
			gradient_top:"#000000",
			gradient_bottom:"#000000",
			discount_amount:"",
			discount_type:"2",
			gradient_type:"horizontal",
			duration:"",
			segments:{},
			duration_type:2,
			text_size:"s",
			slimit:"",
			description:""

		},
		 validationSchema: CouponDetailsSchema,
		enableReinitialize: true,
		onSubmit: (values) => {
			console.log(values)
			const payload:CouponDetails={
				type:values.couponType,
				code:values.code,
				text_size:values.text_size,
				gradient_type:values.gradient_type,
				backgroud_color:values.backgroundColor,
				text_color:values.textColor,
				button_color:values.buttonColor,
				expiry_date:values.date,
				limit:values.slimit,
				text:values.text,
				description:values.description
				
				
				// university_id:values.university,
				// departments:values.departments


			}
			payload.gradient_from=values.gradient_from
				payload.gradient_to=values.gradient_to
				payload.gradient_top=values.gradient_top
				payload.gradient_bottom=values.gradient_bottom
			if(Number(values.couponType)!==6)
			{
				payload.universities=values.selectedUniversities
			}
		
			if(Number(values.couponType)===5||Number(values.couponType)===2)
			{
				payload.text=values.text
				payload.courses_count=values.limit
				payload.courses=values.courses
			}
			if(Number(values.couponType)===4)
			{
				payload.text=values.text;
				payload.courses=[selectedCourse] 
			}
			if(Number(values.couponType)===4)
			{
				payload.text=values.text;
				payload.courses=[selectedCourse] 
			}
			if(Number(values.couponType)===3)
			{
				payload.image=values.bannerImage
				
			}
			if(Number(values.couponType)===6)
			{
				payload.discount_amount=(values.discount_amount),
				payload.discount_type=values.discount_type
				
			}
			if(Number(values.couponType)===1)
			{
				payload.segments=values.segments
				payload.duration=values.duration
				payload.duration_type=values.duration_type?.toString()

			}
			
			toast.promise( 
				selectedCoupon.editOrAdd === INSTRUCTOR.REDUX.ADD
					? addCoupon(payload)
					: editCoupon(payload, selectedCoupon.id as number),
				{
					pending: {
						render() {
							return selectedCoupon.editOrAdd === INSTRUCTOR.REDUX.ADD
								?  MESSAGES.COUPON.ADD
								: MESSAGES.COUPON.EDIT
										
						},
					},
					success: {
						render() {
							couponsDetailsFormik.resetForm();
							handleClose();
							return	selectedCoupon.editOrAdd === INSTRUCTOR.REDUX.ADD
								?  MESSAGES.COUPON.SUCCESS
								: MESSAGES.COUPON.EDITED
			
										
						},
					},
					error: {
						render() {
							return	selectedCoupon.editOrAdd === INSTRUCTOR.REDUX.ADD
								?  MESSAGES.COUPON.INVALID
								:  MESSAGES.COUPON.NOTEDITED
										
						},
					},
				}
			);
			
		
		},
	});


	useEffect(() => {
		const gradientLeft = Number(couponsDetailsFormik.values.couponType) === 2 ? "#FFE602" : "#094E85";
		const gradientRight = Number(couponsDetailsFormik.values.couponType) === 2 ? "#FFFBD5" : "#094E85";

		couponsDetailsFormik.setFieldValue('gradient_from', gradientLeft);
		couponsDetailsFormik.setFieldValue('gradient_to', gradientRight);

	  }, [couponsDetailsFormik.values.couponType]);

	// const selectedCourse = useAppSelector((state) => state.courseReducer);
	// const [instructors, setInstructors] = useState<Instructor[]>([]);
	// const [universities, setUniversities] = useState<UniversityInterface[]>([]);
	// const [department, setDepartment] = useState<DepartmentInterface[]>();
	// const [selectedDepartments, setSelectedDepartments] = useState<number[]>([]);
	// const [pagination, setPagination] = useState<PaginationInterface>({
	// 	page: 1,
	// 	limit: 10,
	// 	count: 0,
	// 	totalPages: 0,
	// });

	// const addInstructor = (id: number) => {
	// 	getAllInstructorUniversity(id).then((res) => {
	// 		setInstructors(res.data);
	// 		console.log(setPagination);
	// 	});
	// };
	// useEffect(() => {
	// 	getAllUniversities().then((res) => {
	// 		setUniversities(res?.data);
	// 	});
	// }, [showModal]);

	// useEffect(() => {
	// 	if (selectedCourse.editOrAdd === INSTRUCTOR.REDUX.EDIT) {
	// 		course(String(selectedCourse.id), pagination).then((res) => {
	// 			const { name, instructor, university, departments, full_price } = res.data;

	// 			courseDetailsFormik.setFieldValue(COURSES.FORMIK.COURSE, name);
	// 			courseDetailsFormik.setFieldValue(COURSES.FORMIK.PRICE, full_price)
	// 			addInstructor(university.id as number);
	// 			courseDetailsFormik.setFieldValue(
	// 				COURSES.FORMIK.INSTRUCTOR,
	// 				instructor.id
	// 			);
	// 			courseDetailsFormik.setFieldValue(
	// 				COURSES.FORMIK.UNIVERSITY,
	// 				university.id
	// 			);
	// 			addDepartments(Number(university.id));
	// 			const depList = departments.map((dep) => dep.department_id);
	// 			setSelectedDepartments(depList);
	// 		});
	// 	} else {
	// 		courseDetailsFormik.resetForm();
	// 		setDepartment([]);
	// 		setSelectedDepartments([]);
	// 	}
	// }, [selectedCourse.editOrAdd, showModal]);

	// const addDepartments = (id: Number) => {
	// 	if (id === 0) {
	// 		setDepartment([]);
	// 		return;
	// 	}
	// 	const selectedUniversity = universities.filter(
	// 		(uni: UniversityInterface) => {
	// 			return uni.id === id;
	// 		}
	// 	);
	// 	setDepartment(selectedUniversity[0]?.universityDepartments);
	// };
	// const handleUniversityChange = (e: any) => {
	// 	courseDetailsFormik.handleChange(e);
	// 	addDepartments(Number(e.target.value));
	// 	if (Number(e.target.value) === 0) {
	// 		setInstructors([]);
	// 		return;
	// 	}
	// 	addInstructor(Number(e.target.value));
	// };

	// const handleCheckboxClick = (e: React.ChangeEvent<HTMLInputElement>) => {
	// 	courseDetailsFormik.setFieldError(COURSES.FORMIK.DEPARTMENT, "");
	// 	if (e.target.checked === true) {
	// 		setSelectedDepartments([...selectedDepartments, Number(e.target.value)]);
	// 	} else {
	// 		const selectedDepartmentsCopy = selectedDepartments;
	// 		const selectedDepartmentsList = selectedDepartmentsCopy.filter((num) => {
	// 			return num !== Number(e.target.value);
	// 		});
	// 		setSelectedDepartments(selectedDepartmentsList);
	// 	}
	// };

	// const handleAddCourse = (e: React.FormEvent<HTMLFormElement>) => {
	// 	e.preventDefault();
	// 	courseDetailsFormik.submitForm();
	// };

	// const courseDetailsFormik = useFormik({
	// // 	initialValues: {
	// // 		course: "",
	// // 		instructor: 0,
	// // 		departments: [],
	// // 		university: 0,
	// // 	},
	// // 	enableReinitialize: true,
	// // 	onSubmit: (values) => {
	// // 		const payload: AddCourseParam = {
	// // 			instructor_id: Number(values.instructor),
	// // 			departments: selectedDepartments,
	// // 			university_id: values.university,
	// // 			name: values.course,
	// // 			isPublished: 1,
	// // 		};
	// // 		if (selectedDepartments.length === 0) {
	// // 			courseDetailsFormik.setFieldError(
	// // 				COURSES.FORMIK.DEPARTMENT,
	// // 				YUP.REQUIRED
	// // 			);
	// // 			return;
	// // 		}
	// // 		toast.promise(
	// // 			selectedCourse.editOrAdd === INSTRUCTOR.REDUX.ADD
	// // 				? addCourse(payload)
	// // 				: editCourse(payload, selectedCourse.id as number),
	// // 			{
	// // 				pending: {
	// // 					render() {
	// // 						return selectedCourse.editOrAdd === INSTRUCTOR.REDUX.ADD
	// // 							? MESSAGES.COURSE.ADD
	// // 							: MESSAGES.COURSE.EDIT;
	// // 					},
	// // 				},
	// // 				success: {
	// // 					render() {
	// // 						courseDetailsFormik.resetForm();
	// // 						handleClose();

	// // 						return selectedCourse.editOrAdd === INSTRUCTOR.REDUX.ADD
	// // 							? MESSAGES.COURSE.ADDED
	// // 							: MESSAGES.COURSE.EDITED;
	// // 					},
	// // 				},
	// // 				error: {
	// // 					render() {
	// // 						return selectedCourse.editOrAdd === INSTRUCTOR.REDUX.ADD
	// // 							? MESSAGES.COURSE.NOTADDED
	// // 							: MESSAGES.COURSE.NOTEDITED;
	// // 					},
	// // 				},
	// // 			}
	// // 		);
	// // 	},
	// // 	validationSchema: CourseDetailsSchema,
	//  });
	const handleImageUpload=(e:any)=>{
		setLoading(true);
		const file=e.target.files[0];
		setBannerImage(URL.createObjectURL(file))

		uploadImage(file).then((res)=>{
			console.log(res);
			couponsDetailsFormik.setFieldValue("bannerImage", res.data[0])
			setLoading(false)
		})
	}
	return (
		<Modal
			show={showModal}
			onHide={() => {
				handleClose();
			}}
		>
			<div className={styles["add-lead-from"]}>
				<Modal.Header closeButton>
					<Modal.Title>
						{"Coupons"}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body onClick={()=>{console.log(couponsDetailsFormik.errors);console.log(couponsDetailsFormik.values)}}>
					<Form onSubmit={()=>{}}>
				
						<div className={styles.fieldStyle}>

							<Form.Group className="from-group" controlId="formUniversity">
								<Form.Label>Type</Form.Label>
								<Form.Control
									disabled={loading}
									name={COUPONS.FORMIK.COUPON_TYPE}
									as="select"
									value={couponsDetailsFormik.values.couponType}
									onChange={couponsDetailsFormik.handleChange}
								>
									
								
									<option value={1}>
										{"Group"}
									</option>
									<option value={2}>
										{"Course Discount"}
									</option>
								
									<option value={3}>
										{"Special Package"}
									</option>
								
									<option value={6}>
										{"Simple Coupon"}
									</option>
										
								
								</Form.Control>
							</Form.Group>
						
						</div>	
						{/* <div className={styles.fieldStyle}>

							<Form.Group className="from-group" controlId="formUniversity">
								<Form.Label>University</Form.Label>
								<Form.Control
									name={COURSES.FORMIK.UNIVERSITY}
									value={courseDetailsFormik.values.university}
									as="select"
									onChange={(e) => handleUniversityChange(e)}
								>
									<option value={0} key={0}>
                    Select University
									</option>
									{universities?.map((university, id) => (
										<option value={university.id} key={id}>
											{university.name}
										</option>
									))}
								</Form.Control>
							</Form.Group>
							{courseDetailsFormik.errors.university &&
              courseDetailsFormik.touched.university ? (
									<span className={commonstyles.error}>
										{courseDetailsFormik.errors.university}
									</span>
								) : null}
						</div>

						<div className={styles.fieldStyle}>
							<Form.Group className="from-group" controlId="formUniversity">
								<Form.Label>Instructor</Form.Label>
								<Form.Control
									name={COURSES.FORMIK.INSTRUCTOR}
									value={courseDetailsFormik.values.instructor}
									as="select"
									onChange={courseDetailsFormik.handleChange}
								>
									<option value={0} key={0}>
                    Select Instructor
									</option>
									{instructors?.map((instructor, id) => (
										<option value={instructor.id} key={id}>
											{instructor.full_name}
										</option>
									))}
								</Form.Control>
							</Form.Group>
							{courseDetailsFormik.errors.instructor &&
              courseDetailsFormik.touched.instructor ? (
									<span className={commonstyles.error}>
										{courseDetailsFormik.errors.instructor}
									</span>
								) : null}
						</div>

						{courseDetailsFormik.values.university > 0 && (
							<div className={styles.fieldStyle}>
								<Form.Label>Departments</Form.Label>

								{department?.map((res, id) => {
									return (
										<Form.Check
											key={id}
											className="from-check"
											value={res.id as number}
											type="checkbox"
											checked={
												selectedDepartments.includes(res.id as number)
													? true
													: false
											}
											onChange={handleCheckboxClick}
											label={res.name}
										></Form.Check>
									);
								})}

								{courseDetailsFormik.errors.departments &&
                courseDetailsFormik.touched.departments ? (
										<span className={commonstyles.error}>
											{courseDetailsFormik.errors.departments}
										</span>
									) : null}
							</div>
						)} */}

						{/* <Button variant="outline-secondary" className="mb-2">
                    <i className="fas fa-plus"></i> Choose More Department
                </Button> */}
						<hr />
						{/* <Button variant="outline-secondary" className="mb-2">
                    <i className="fas fa-plus"></i> Add More University
                </Button> */}
						{/* <Button className={`${styles.buttonss} mt-3`} type="submit">
							{selectedCourse.editOrAdd === INSTRUCTOR.REDUX.ADD
								? INSTRUCTOR.ADD
								: INSTRUCTOR.EDIT}
						</Button> */}
						
						{<>{Number(couponsDetailsFormik.values.couponType)===1&&<Form.Group className="from-group" controlId="formFirstName">
							<Form.Label>Duration</Form.Label>
							<Form.Control
								disabled={loading}
								type="number"
								value={couponsDetailsFormik.values.duration}									
								onChange={couponsDetailsFormik.handleChange}
								placeholder="Duration"
								name={"duration"}
									
							/>
						</Form.Group>}
						{couponsDetailsFormik.errors.duration &&
								couponsDetailsFormik.touched.duration ? (
													  <span className={commonstyles.error}>
														  {couponsDetailsFormik.errors.duration }
													  </span>
												  ) : null}
												  </>
						}
						{Number(couponsDetailsFormik.values.couponType)===1&&<>
						<Form.Label onClick={()=>{console.log(couponsDetailsFormik.errors.selectedUniversities)}}>Unit</Form.Label>
										<Form.Select
											disabled={loading}
								
											as="select"
											
											onChange={couponsDetailsFormik.handleChange}
											name={"duration_type"}		
										>
									
								
									<option value={1} key={0}>
                    Minute
								</option>
								<option value={2} key={2}>
                  Hour
								</option>
								<option value={3} key={5}>
                  Day
								</option>
										
								
										</Form.Select>
										</>
						}


						{<>{(Number(couponsDetailsFormik.values.couponType)===2||Number(couponsDetailsFormik.values.couponType)===3)&&<Form.Group className="from-group" controlId="formFirstName">
							<Form.Label>Text</Form.Label>
							<Form.Control
								disabled={loading}
								type="text"
								value={couponsDetailsFormik.values.text}
								placeholder="Text"
								name={COUPONS.FORMIK.TEXT}
								onChange={couponsDetailsFormik.handleChange}
									
							/>
						</Form.Group>}
						{couponsDetailsFormik.errors.text &&
								couponsDetailsFormik.touched.text ? (
													  <span className={commonstyles.error}>
														  {couponsDetailsFormik.errors.text }
													  </span>
												  ) : null}
						</>}
						{Number(couponsDetailsFormik.values.couponType)===3&&<Form.Group className="from-group" controlId="formFirstName">
							
							<Form.Label>Description</Form.Label>
							<Form.Control
								disabled={loading}
								type="text"
								value={couponsDetailsFormik.values.description}
								placeholder="Description"
								name={"description"}
								onChange={couponsDetailsFormik.handleChange}
									
							/>
						</Form.Group>}
						{<>{(Number(couponsDetailsFormik.values.couponType)===5||Number(couponsDetailsFormik.values.couponType)===2)&&<Form.Group className="from-group" controlId="formFirstName">
							<Form.Label>Course Limit</Form.Label>
							<Form.Control
								type="number"
								disabled={loading}
								placeholder="number"
								onChange={couponsDetailsFormik.handleChange}
								value={couponsDetailsFormik.values.limit}

								name={COUPONS.FORMIK.LIMIT}
									
							/>
						</Form.Group>}
						{couponsDetailsFormik.errors.limit &&
								couponsDetailsFormik.touched.limit ? (
													  <span className={commonstyles.error}>
														  {couponsDetailsFormik.errors.limit }
													  </span>
												  ) : null}
						</>}
						
						{<>{Number(couponsDetailsFormik.values.couponType)!==6&&Number(couponsDetailsFormik.values.couponType)!==1&&<Form.Group className="from-group" controlId="formFirstName">
							<Form.Label>University</Form.Label>
							
                    
							{universities?.map((university, id) => (
								<>
									<Form.Check
										key={id}
										disabled={loadingCourse}
										className="from-check"
										value={university.id as number}
										type="checkbox"
										checked={couponsDetailsFormik?.values?.selectedUniversities?.some(
											(u) => u.university_id === university.id
									  )}
										onChange={(e)=>{console.log(couponsDetailsFormik.values.selectedUniversities, "rrr"); handleUniversityCheckboxClick(e, university.id)}}
										label={<>{university.name}{couponsDetailsFormik?.values?.selectedUniversities?.some(
											(u) => u.university_id === university.id
									  )&&	<Button style={{marginLeft:"60px"}} onClick={(e)=>addMore(university.id)}>Add More Package</Button>}</> }
									></Form.Check>
								
									
									{<>{Number(couponsDetailsFormik.values.couponType)!==0&&couponsDetailsFormik.values.selectedUniversities?.map((u,ind) =>{ if(u.university_id === university.id)
										{
											return <>
											<Form.Group className="from-group" controlId="formFirstName">
											<Form.Label>discount</Form.Label>
											<Form.Control
												disabled={loading}
												onClick={()=>{console.log( calculateUniIndex(couponsDetailsFormik.values.selectedUniversities,university.id),"odsjdlwkjhdwd")}}
												value={
													searchUniversity(university.id, "discount",ind)
	
												}
												name={`selectedUniversities.${ind}.discount`}
												type="number"
									
												placeholder="Discount"
												onChange={couponsDetailsFormik.handleChange}		
											/>
								
								 {/* {
								//@ts-ignore
								
								couponsDetailsFormik?.touched?.selectedUniversities?.length&&couponsDetailsFormik?.touched?.selectedUniversities[0]?.discount&&<ErrorMessage name={`selectedUniversities.${couponsDetailsFormik.values.selectedUniversities.findIndex(
									(u:any) => u.university_id === university.id
								  )}.discount`} component="div" className="error" />} */}
	
								 
											{/* 
	{
		//@ts-ignore
	couponsDetailsFormik.errors.selectedUniversities.length>0 &&
		couponsDetailsFormik.touched.selectedUniversities ? (
							  <span className={commonstyles.error}>
								  {couponsDetailsFormik.errors.selectedUniversities }
							  </span>
						  ) : null} */}
							   {couponsDetailsFormik.errors.selectedUniversities &&
									couponsDetailsFormik.touched.selectedUniversities&&Array.isArray(couponsDetailsFormik.errors.selectedUniversities) ? (
														  <span className={commonstyles.error}>
															
															  {
																
																//@ts-ignore
																couponsDetailsFormik.errors?.selectedUniversities[calculateUniIndex(couponsDetailsFormik.values.selectedUniversities,university.id)]?.discount
															   }
														  </span>
													  ) : null}
							  {/* <span className={commonstyles.error}>
							  {couponsDetailsFormik.errors.selectedUniversities &&
									couponsDetailsFormik.touched.selectedUniversities? (
														  <span className={commonstyles.error}>
	
															  {
															  //@ts-ignore
															  couponsDetailsFormik.errors?.selectedUniversities[0]?.discount &&selectedUniversities[0]?.discount }
														  </span>
													  ) : null}
	
	</span> */}
							
										</Form.Group>
										<Form.Group className="from-group" controlId="formUniversity">
										<Form.Label onClick={()=>{console.log(couponsDetailsFormik.errors.selectedUniversities)}}>Unit</Form.Label>
										<Form.Control
											disabled={loading}
								
											as="select"
											value={
												searchUniversity(university.id, "discount_type",ind)
	
											}
											onChange={couponsDetailsFormik.handleChange}
											name={`selectedUniversities.${ind}.discount_type`}		
										>
									
								
											<option value={2}>
												{"%"}
											</option>
											<option value={1}>
												{"KD"}
											</option>
										
								
										</Form.Control>
									</Form.Group>
									<><Form.Group style={{height:"200px",marginBottom:"20px"}} className="overflow-auto   from-group" controlId="formUniversity">
										<Form.Label >Courses:</Form.Label>
										{/* <Form.Control
											disabled={loading}
								
											as="ch"
											value={
												searchUniversity(university.id, "courses")
	
											}
											onChange={couponsDetailsFormik.handleChange}
											name={`selectedUniversities.${couponsDetailsFormik.values.selectedUniversities.findIndex(
												(u) => u.university_id === university.id
								  )}.courses`}		
										>
									
								
											<option value={2}>
												{"%"}
											</option>
											<option value={1}>
												{"KD"}
											</option>
										
								
										</Form.Control> */}
										{couponsDetailsFormik.values.selectedUniversities[ind]?.allCourses?.map((course:any)=>{
											console.log(course,"dfsdfsfsd")
											return <Form.Check
											 key={course.id}
											 type="checkbox"
											 id={`checkbox-${course.name}`}
											label={course.name}
											 value={course.id}
											 checked={searchUniversity(university.id, "courses",ind).includes(course.id)}
											 onChange={(e) => {handleCourseCheckbox(e,university.id,course.id,ind)}}
										/>

										})}
										
							
									</Form.Group>
									{couponsDetailsFormik.errors.selectedUniversities &&
	couponsDetailsFormik.touched.selectedUniversities&&Array.isArray(couponsDetailsFormik.errors.selectedUniversities) ? (
						  <span className={commonstyles.error}>
							
							  {
								//@ts-ignore
								couponsDetailsFormik.errors?.selectedUniversities[calculateUniIndex(couponsDetailsFormik.values.selectedUniversities,university.id)]?.courses
							   }
						  </span>
					  ) : null}

</>
										</>
										}
									})
									}
						
						
									</>}
									{/* {couponsDetailsFormik.values.selectedUniversities?.some((u) => u.university_id === university.id)&&<Form.Group className="from-group" controlId="formUniversity">
										<Form.Label onClick={()=>{console.log(couponsDetailsFormik.errors.selectedUniversities)}}>Unit</Form.Label>
										<Form.Control
											disabled={loading}
								
											as="select"
											value={
												searchUniversity(university.id, "discount_type")
	
											}
											onChange={couponsDetailsFormik.handleChange}
											name={`selectedUniversities.${couponsDetailsFormik.values.selectedUniversities.findIndex(
												(u) => u.university_id === university.id
								  )}.discount_type`}		
										>
									
								
											<option value={2}>
												{"%"}
											</option>
											<option value={1}>
												{"KD"}
											</option>
										
								
										</Form.Control>
									</Form.Group>} */}
								
								</>
								
							))}
								{couponsDetailsFormik.errors.selectedUniversities &&
								couponsDetailsFormik.touched.selectedUniversities&&couponsDetailsFormik.errors.selectedUniversities==="Required" ? (
													  <span className={commonstyles.error}>
														
														  {
															//@ts-ignore
															couponsDetailsFormik.errors.selectedUniversities as string
														   }
													  </span>
												  ) : null}
							
						</Form.Group>}
					
						</>}


						{<>{Number(couponsDetailsFormik.values.couponType)===1&&<>
							<Form.Label>University</Form.Label>
							
                    
							{universities?.map((university, id) => (
								<>
									<Form.Check
										key={id}
										disabled={loading}
										className="from-check"
										//@ts-ignore
								
										type="checkbox"
										
										checked={couponsDetailsFormik?.values?.segments&&couponsDetailsFormik?.values?.segments.hasOwnProperty(university.id)}
									// 	checked={couponsDetailsFormik.values.selectedUniversities.some(
									// 		(u) => u.university_id === university.id
									//   )}
										onChange={(e)=>{console.log(couponsDetailsFormik.values.selectedUniversities, "rrr"); handleUniversityChange(e, university.id)}}
										label={university.name}
									></Form.Check>
									{
										//@ts-ignore
										couponsDetailsFormik.values.segments[university.id]&&<Button onClick={()=>addConfis(university.id)}>Add More Segment</Button>}
										{//@ts-ignore
										couponsDetailsFormik.values.segments[university.id]?.map((obj,ind)=>{
											return <>
											<Form.Group>
											<Form.Label>Min Members</Form.Label>
							<Form.Control
								disabled={loading}
								type="number"
								//@ts-ignore
								value={couponsDetailsFormik.values?.segments[university.id][ind]?.min}									
								onChange={couponsDetailsFormik.handleChange}
								placeholder="No. of members"
								name={`segments[${university.id}][${ind}].min`}
									
							/>
							{couponsDetailsFormik.errors.segments &&
								couponsDetailsFormik.touched.segments&&couponsDetailsFormik.errors?.segments[university.id] ? (
													  <span className={commonstyles.error}>
														  {
														  //@ts-ignore
														   couponsDetailsFormik.errors?.segments[university.id][ind]?.min 
														  }
													  </span>
												  ) : null}

							

												 
							
							</Form.Group>
							
							<Form.Group>
							<Form.Label>Max Members</Form.Label>
							<Form.Control
								disabled={loading}
								type="number"
								//@ts-ignore
								value={couponsDetailsFormik.values?.segments[university.id][ind]?.max}								
								onChange={couponsDetailsFormik.handleChange}
								placeholder="No. of members"
								name={`segments[${university.id}][${ind}].max`}
									
							/>
							{couponsDetailsFormik.errors.segments &&
								couponsDetailsFormik.touched.segments &&couponsDetailsFormik.errors?.segments[university.id]? (
													  <span className={commonstyles.error}>
														  {
														  //@ts-ignore
														  couponsDetailsFormik.errors?.segments[university.id][ind]?.max }
													  </span>
												  ) : null}
							</Form.Group>
							<Form.Group>
							<Form.Label>Discount</Form.Label>
							<Form.Control
								disabled={loading}
								type="number"
								//@ts-ignore
								value={couponsDetailsFormik.values?.segments[university.id][ind]?.discount}								
								onChange={couponsDetailsFormik.handleChange}
								placeholder="Discount"
								name={`segments[${university.id}][${ind}].discount`}
									
							/>
							
							{couponsDetailsFormik.errors.segments &&
								couponsDetailsFormik.touched.segments &&couponsDetailsFormik.errors?.segments[university.id] ? (
													  <span className={commonstyles.error}>
														  {
														  //@ts-ignore
														  couponsDetailsFormik?.errors?.segments[university.id][ind]?.discount }
													  </span>
												  ) : null}
							</Form.Group>
							<Form.Group className="from-group" controlId="formUniversity">
								<Form.Label>Unit</Form.Label>
								<Form.Select
										disabled={loading}
										
										//@ts-ignore
										value={couponsDetailsFormik.values.segments[university.id][ind].discount_type}									
										onChange={couponsDetailsFormik.handleChange}
										name={`segments[${university.id}][${ind}].discount_type`}
											
								>
									<option value={2}>
										{"%"}
									</option>
									<option value={1}>
										{"KD"}
									</option>
										
								
								</Form.Select>
							</Form.Group>
						

											
											
											
											</>
										})
										
									}
                                    
									{/* {
										//@ts-ignore
									selectedUniversities[university.id]&&<>
									<Form.Label>MIN Members</Form.Label>
							<Form.Control
								disabled={loading}
								type="number"
								value={couponsDetailsFormik.values.members}									
								onChange={couponsDetailsFormik.handleChange}
								placeholder="No. of members"
								name={COUPONS.FORMIK.MEMBERS}
									
							/>

<Form.Label>Max Members</Form.Label>
							<Form.Control
								disabled={loading}
								type="number"
								value={couponsDetailsFormik.values.members}									
								onChange={couponsDetailsFormik.handleChange}
								placeholder="No. of members"
								name={COUPONS.FORMIK.MEMBERS}
									
							/>
							<Form.Label>Price</Form.Label>
							<Form.Control
								disabled={loading}
								type="number"
								value={couponsDetailsFormik.values.members}									
								onChange={couponsDetailsFormik.handleChange}
								placeholder="No. of members"
								name={COUPONS.FORMIK.MEMBERS}
									
							/>
							<Form.Label>Unit</Form.Label>
							<Form.Control
								disabled={loading}
								type="number"
								value={couponsDetailsFormik.values.members}									
								onChange={couponsDetailsFormik.handleChange}
								placeholder="No. of members"
								name={COUPONS.FORMIK.MEMBERS}
									
							/>
									
									
									
									</>} */}
									
									
									
								</>
							))}
							
						</>}
						{couponsDetailsFormik.errors?.segments &&
								couponsDetailsFormik?.touched?.segments &&couponsDetailsFormik?.errors?.segments ? (
													  <span className={commonstyles.error}>
														  {
														  //@ts-ignore
														  typeof couponsDetailsFormik?.errors?.segments==="string"&& couponsDetailsFormik?.errors?.segments }
													  </span>
												  ) : null}
						</>}

						
						
						{/* {(couponsDetailsFormik.values.university > 0) && (
							<div className={styles.fieldStyle}>
								<Form.Label>Departments</Form.Label>

								{department?.map((res, id) => {
									return (
										<Form.Check
											key={id}
											className="from-check"
											value={res.id as number}
											type="checkbox"
											checked={
												selectedDepartments.includes(res.id as number)
													? true
													: false
											}
											onChange={handleCheckboxClick}
											label={res.name}
										></Form.Check>
									);
								})}

								{couponsDetailsFormik.errors.departments &&
                couponsDetailsFormik.touched.departments ? (
										<span className={commonstyles.error}>
											{couponsDetailsFormik.errors.departments}
										</span>
									) : null}
							</div>
						)} */}
						
					
						{(Number(couponsDetailsFormik.values.couponType)===3)&&<Form.Group className="from-group" controlId="formFirstName">
							<Form.Label>Banner Image</Form.Label>
							<Form.Control
								type="file"
								name={"bannerImage"}
								disabled={loading}					
								onChange={handleImageUpload}
								placeholder="No. of members"
								
									
							/>
						</Form.Group>}
						<Form.Group>
					{(Number(couponsDetailsFormik.values.couponType)!==6)&&	<Form.Check
					style={{marginTop:"30px"}}
					
											key={1}
											className="from-check"
											type="checkbox"
											checked={
												customised
											}
											onChange={(e)=>{setCustomised(e.target.checked)}}
											label={"Customize"}
										></Form.Check>}
										</Form.Group>
						{(Number(couponsDetailsFormik.values.couponType)!==6&&customised)&&<>
						<Form.Group className="from-group" controlId="formUniversity">
								<Form.Label onClick={()=>{console.log(couponsDetailsFormik.errors.selectedUniversities)}}>Text Size</Form.Label>
								<Form.Control
									disabled={loading}
								
									as="select"
									value={
										couponsDetailsFormik.values.text_size
	
									}
									onChange={couponsDetailsFormik.handleChange}
									name={"text_size"}		
								>
									
								
									<option value={"s"}>
										{"Small"}
									</option>
									<option value={"m"}>
										{"Medium"}
									</option>
									<option value={"l"}>
										{"Large"}
									</option>
										
								
								</Form.Control>
							</Form.Group>
						<Form.Group className="from-group" controlId="formUniversity">
								<Form.Label onClick={()=>{console.log(couponsDetailsFormik.errors.selectedUniversities)}}>Gradient Direction</Form.Label>
								<Form.Control
									disabled={loading}
								
									as="select"
									value={
										couponsDetailsFormik.values.gradient_type
	
									}
									onChange={couponsDetailsFormik.handleChange}
									name={"gradient_type"}		
								>
									
								
									<option value={"horizontal"}>
										{"Horizontal"}
									</option>
									<option value={"vertical"}>
										{"Vertical"}
									</option>
										
								
								</Form.Control>
							</Form.Group>
							{couponsDetailsFormik.values.gradient_type==="horizontal"?<>
						<Form.Label>Gradient Left</Form.Label>

						<span className={styles.colorspan}>
	<input disabled={loading} value={couponsDetailsFormik.values.gradient_from} onChange={couponsDetailsFormik.handleChange} name={"gradient_from"}  type="color"/>
	<input disabled={loading} value={couponsDetailsFormik.values.gradient_from} readOnly  name={"gradient_from"} className="colortext" type="text"/>
</span>
<Form.Label>Gradient Right</Form.Label>

<span className={styles.colorspan}>
	<input disabled={loading} value={couponsDetailsFormik.values.gradient_to} onChange={couponsDetailsFormik.handleChange} name={"gradient_to"}  type="color"/>
	<input disabled={loading} value={couponsDetailsFormik.values.gradient_to} readOnly  name={"gradient_to"} className="colortext" type="text"/>
</span>
</>:<><Form.Label>Gradient Top</Form.Label>

<span className={styles.colorspan}>
	<input disabled={loading} value={couponsDetailsFormik.values.gradient_top} onChange={couponsDetailsFormik.handleChange} name={"gradient_top"}  type="color"/>
	<input disabled={loading} value={couponsDetailsFormik.values.gradient_top} readOnly  name={"gradient_top"} className="colortext" type="text"/>
</span>
<Form.Label>Gradient Bottom</Form.Label>

<span className={styles.colorspan}>
	<input disabled={loading} value={couponsDetailsFormik.values.gradient_bottom} onChange={couponsDetailsFormik.handleChange} name={"gradient_bottom"}  type="color"/>
	<input disabled={loading} value={couponsDetailsFormik.values.gradient_bottom} readOnly  name={"gradient_bottom"} className="colortext" type="text"/>
</span></>}

						{/* {<Form.Group className="from-group" controlId="formFirstName">
							<Form.Label>Banner Gradient Left</Form.Label>
							<Form.Control
								onChange={couponsDetailsFormik.handleChange}
								name={"gradient_from"}
								disabled={loading}
								
								as="select">
									
								
								<option value={"#00FF00"}>
									{"Green"}
								</option>
								<option value={"#FF0000"}>
									{"red"}
								</option>
								<option value={"#0000FF"}>
									{"Blue"}
								</option>
										
								
							</Form.Control>
						</Form.Group>} */}
						{/* {<Form.Group className="from-group" controlId="formFirstName">
							<Form.Label>Banner Gradient Right</Form.Label>
							<Form.Control
								name="gradient_to"
								disabled={loading}
								onChange={couponsDetailsFormik.handleChange}
								as="select"
							>
								<option value={"#00FF00"}>
									{"Green"}
								</option>
								<option value={"#FF0000"}>
									{"red"}
								</option>
								<option value={"#0000FF"}>
									{"Blue"}
								</option>

							</Form.Control>
						</Form.Group>}
						{<Form.Group className="from-group" controlId="formFirstName">
							<Form.Label>Banner Gradient Top</Form.Label>
							<Form.Control
								onChange={couponsDetailsFormik.handleChange}
								name={"gradient_top"}
								disabled={loading}
								
								as="select">
									
								
								<option value={"#00FF00"}>
									{"Green"}
								</option>
								<option value={"#FF0000"}>
									{"red"}
								</option>
								<option value={"#0000FF"}>
									{"Blue"}
								</option>
										
								
							</Form.Control>
						</Form.Group>}
						
						{<Form.Group className="from-group" controlId="formFirstName">
							<Form.Label>Banner Gradient Bottom</Form.Label>
							<Form.Control
								name="gradient_bottom"
								disabled={loading}
								onChange={couponsDetailsFormik.handleChange}
								as="select"
							>
								<option value={"#00FF00"}>
									{"Green"}
								</option>
								<option value={"#FF0000"}>
									{"red"}
								</option>
								<option value={"#0000FF"}>
									{"Blue"}
								</option>

							</Form.Control>
						</Form.Group>} */}
						{(Number(couponsDetailsFormik.values.couponType)!==6)&&<><Form.Label>Text Color</Form.Label>

<span className={styles.colorspan}>
	<input disabled={loading} value={couponsDetailsFormik.values.textColor} name={COUPONS.FORMIK.TEXT_COLOR} onChange={couponsDetailsFormik.handleChange}  type="color"/>
	<input  readOnly value={couponsDetailsFormik.values.textColor}  className="colortext" type="text"/>
</span>
<Form.Label>Button Color</Form.Label>

<span className={styles.colorspan}>
	<input value={couponsDetailsFormik.values.buttonColor}  onChange={couponsDetailsFormik.handleChange} name="buttonColor"  type="color"/>
	<input readOnly value={couponsDetailsFormik.values.buttonColor} name={COUPONS.FORMIK.BUTTON_COLOR} className="colortext" type="text"/>
</span>
</>}
						</>
						
						}
						{Number(couponsDetailsFormik.values.couponType)===6&&<>
							<Form.Group className="from-group" controlId="formFirstName">
								<Form.Label>Discount</Form.Label>
								<Form.Control
									disabled={loading}
									type="number"
									value={couponsDetailsFormik.values.discount_amount}									
									onChange={couponsDetailsFormik.handleChange}
									placeholder="Discount"
									name={COUPONS.FORMIK.DISCOUNT_AMOUNT}
									
								/>
							</Form.Group>
							{couponsDetailsFormik.errors.discount_amount &&
								couponsDetailsFormik.touched.discount_amount ? (
													  <span className={commonstyles.error}>
														  {couponsDetailsFormik.errors.discount_amount }
													  </span>
												  ) : null}


												  <Form.Group className="from-group" controlId="formUniversity">
								<Form.Label onClick={()=>{console.log(couponsDetailsFormik.errors.selectedUniversities)}}>Unit</Form.Label>
								<Form.Control
									disabled={loading}
								
									as="select"
									value={
										couponsDetailsFormik.values.discount_type
	
									}
									onChange={couponsDetailsFormik.handleChange}
									name={COUPONS.FORMIK.DISCOUNT_TYPE}		
								>
									
								
									<option value={2}>
										{"%"}
									</option>
									<option value={1}>
										{"KD"}
									</option>
										
								
								</Form.Control>
							</Form.Group>
						</>}
						
						{/* <Form.Label>Background Color</Form.Label>

<span className={styles.colorspan}>
	<input disabled={loading} value={couponsDetailsFormik.values.backgroundColor} onChange={couponsDetailsFormik.handleChange} name="backgroundColor"  type="color"/>
	<input disabled={loading} value={couponsDetailsFormik.values.backgroundColor} readOnly  name={COUPONS.FORMIK.BACKGROUND_COLOR} className="colortext" type="text"/>
</span> */}
<div className={styles.fieldStyle}>
	
{(Number(couponsDetailsFormik.values.couponType)===6)&&<><Form.Group className="from-group" controlId="formFirstName">
		<Form.Label>Coupon Code</Form.Label>
		<Form.Control disabled={loading} onChange={couponsDetailsFormik.handleChange}
			value={couponsDetailsFormik.values.code}
			type="text"
			placeholder="code"
			name={COUPONS.FORMIK.CODE}
			
		/>
	</Form.Group>
	{couponsDetailsFormik.errors.code &&
couponsDetailsFormik.touched.code ? (
			<span className={commonstyles.error}>
				{couponsDetailsFormik.errors.code }
			</span>
		) : null}</>}
	{(Number(couponsDetailsFormik.values.couponType)!==1)&&<Form.Group className="from-group" controlId="formFirstName">
		<Form.Label>Validity</Form.Label>
		<Form.Control
			disabled={loading}
			onChange={couponsDetailsFormik.handleChange}
			value={couponsDetailsFormik.values.date}
			type="date"
			placeholder="date"
			name={COUPONS.FORMIK.DATE}
			min={new Date().toJSON().slice(0, 10)}
			
		/>
	</Form.Group>}
	{couponsDetailsFormik.errors.date &&
couponsDetailsFormik.touched.date ? (
			<span className={commonstyles.error}>
				{couponsDetailsFormik.errors.date }
			</span>
		) : null}
		{Number(couponsDetailsFormik.values.couponType)===6&&<Form.Group className="from-group" controlId="formFirstName">
							<Form.Label>limit</Form.Label>
							<Form.Control
								disabled={loading}
								type="number"
								value={couponsDetailsFormik.values.slimit}									
								onChange={couponsDetailsFormik.handleChange}
								placeholder="limit"
								name={"slimit"}
									
							/>
						</Form.Group>}
	{couponsDetailsFormik.errors.slimit &&
couponsDetailsFormik.touched.slimit ? (
			<span className={commonstyles.error}>
				{couponsDetailsFormik.errors.slimit}
			</span>
		) : null}
</div>	
					</Form>
					
					{/* {(Number(couponsDetailsFormik.values.couponType)===5||Number(couponsDetailsFormik.values.couponType)===2||Number(couponsDetailsFormik.values.couponType)===3)&&<div className={styles.CouponCode} style={{color: couponsDetailsFormik.values.textColor, backgroundColor: couponsDetailsFormik.values.backgroundColor}}>
						<div className={styles.outerCoupon} style={{color: couponsDetailsFormik.values.textColor, backgroundColor: couponsDetailsFormik.values.backgroundColor}}>
							<div className={styles.leftCoupon}>
								<h6 style={{color: couponsDetailsFormik.values.textColor}}>Coupon Voucher</h6>
								<h4 style={{color: couponsDetailsFormik.values.textColor}}>{`${"x %"} Off`}</h4>
							</div>
							<div className={styles.rightCoupon}>
								<h4 style={{color: couponsDetailsFormik.values.textColor}}>{`${couponsDetailsFormik.values.text}`}</h4>
								<h5 style={{color: couponsDetailsFormik.values.textColor}}>{`${"X %"} Off`} <span>on {couponsDetailsFormik.values.limit} courses</span></h5>
								<h6 style={{color: couponsDetailsFormik.values.textColor}}>Valid until of {moment(couponsDetailsFormik.values.date).format("MMM DD, YYYY")}</h6>
								<span style={{color: couponsDetailsFormik.values.textColor, backgroundColor: couponsDetailsFormik.values.buttonColor}} className={styles.tag}>{`${couponsDetailsFormik.values.code}`}</span>
								<div className={styles.copyBtn}>
									<Link style={{color: couponsDetailsFormik.values.textColor, backgroundColor: couponsDetailsFormik.values.buttonColor}} to="#"><img src={cpy} alt=""/> Copy Code</Link>
									<Link style={{color: couponsDetailsFormik.values.textColor, backgroundColor: couponsDetailsFormik.values.buttonColor}} to="#"><img src={shr} alt=""/> Share</Link>
								</div>
							</div>
						</div>
					</div>}
					{	(Number(couponsDetailsFormik.values.couponType)===1||Number(couponsDetailsFormik.values.couponType)===4)&&<div style={{color: couponsDetailsFormik.values.textColor, backgroundColor: couponsDetailsFormik.values.backgroundColor}} className={styles.CouponCode}>
						<div onClick={()=>{console.log(couponsDetailsFormik.errors)}} style={{color: couponsDetailsFormik.values.textColor}}  className={styles.outerCoupon}>
							<div className={styles.leftCoupon}>
								<img style={{backgroundColor: couponsDetailsFormik.values.buttonColor}} src={users} alt=""/>
								<span style={{color: couponsDetailsFormik.values.textColor}}>{`${"X %"}` }<br/> <b>OFF</b></span>
							</div>
							<div style={{color: couponsDetailsFormik.values.textColor}} className={styles.rightCoupon}>
								<h5 style={{color: couponsDetailsFormik.values.textColor}}>{`${"X %"} OFF` }</h5>
								<h6 style={{color: couponsDetailsFormik.values.textColor}}>{`Valid until of ${moment(couponsDetailsFormik.values.date).format("MMM DD, YYYY")}`}</h6>
								<span style={{color: couponsDetailsFormik.values.textColor, backgroundColor: couponsDetailsFormik.values.buttonColor}} className={styles.tag}>{`${couponsDetailsFormik.values.code}`}</span>
								<div className={styles.copyBtn}>
									<Link style={{color: couponsDetailsFormik.values.textColor, backgroundColor: couponsDetailsFormik.values.buttonColor}} to="#"><img src={cpy} alt=""/> Copy Code</Link>
									<Link style={{color: couponsDetailsFormik.values.textColor, backgroundColor: couponsDetailsFormik.values.buttonColor}} to="#"><img src={shr} alt=""/> Share</Link>
								</div>
							</div>
						</div>
					</div>} */}
					{			Number(couponsDetailsFormik.values.couponType)===1&&<div className="coupon_cour " style={couponsDetailsFormik.values.gradient_type==="horizontal"?{backgroundImage: `linear-gradient(to right, ${couponsDetailsFormik.values.gradient_from}, ${couponsDetailsFormik.values.gradient_to})`}:{backgroundImage: `linear-gradient(to bottom, ${couponsDetailsFormik.values.gradient_top}, ${couponsDetailsFormik.values.gradient_bottom})`}}>
	
						<div className="d-flex justify-content-between">
	<div className={`${styles.inner_coupon} `}>
	<img
	src={users}
	height="40"
	width="40"
	alt="avatar"
	/>
		{couponsDetailsFormik.values.text_size===null&&<h5 style={{color: couponsDetailsFormik.values.textColor}} className="mb-0 text-center">X %/KD</h5>}

	{couponsDetailsFormik.values.text_size==="s"&&<p style={{color: couponsDetailsFormik.values.textColor}} className="mb-0 text-center">X %/KD</p>}
	{couponsDetailsFormik.values.text_size==="m"&&<h5 style={{color: couponsDetailsFormik.values.textColor}} className="mb-0 text-center">X %/KD</h5>}
	{couponsDetailsFormik.values.text_size==="l"&&<h6 style={{color: couponsDetailsFormik.values.textColor}} className="mb-0 text-center">X %/KD</h6>}
	{couponsDetailsFormik.values.text_size===null&&<h5 style={{color: couponsDetailsFormik.values.textColor}}>OFF</h5>}

	{couponsDetailsFormik.values.text_size==="s"&&<p style={{color: couponsDetailsFormik.values.textColor}}>OFF</p>}
	{couponsDetailsFormik.values.text_size==="m"&&<h5 style={{color: couponsDetailsFormik.values.textColor}}>OFF</h5>}
    {couponsDetailsFormik.values.text_size==="l"&&<h4 style={{color: couponsDetailsFormik.values.textColor}}>OFF</h4>}

	</div>
	<div className={styles.days_Lefts}>
	{couponsDetailsFormik.values.text_size===null&&<h5 style={{color: couponsDetailsFormik.values.textColor}} className="mb-0">X Members</h5>}

	{couponsDetailsFormik.values.text_size==="s"&&<p style={{color: couponsDetailsFormik.values.textColor}} className="mb-0">X Members</p>}
	{couponsDetailsFormik.values.text_size==="m"&&<h5 style={{color: couponsDetailsFormik.values.textColor}} className="mb-0">X Members</h5>}
	{couponsDetailsFormik.values.text_size==="l"&&<h4 style={{color: couponsDetailsFormik.values.textColor}} className="mb-0">X Members</h4>}
	{couponsDetailsFormik.values.text_size===null&&<h6>Use this coupon to enjoy special discount</h6>}

	{couponsDetailsFormik.values.text_size==="s"&&<p>Use this coupon to enjoy special discount</p>}
	{couponsDetailsFormik.values.text_size==="m"&&<h5>Use this coupon to enjoy special discount</h5>}

	{couponsDetailsFormik.values.text_size==="l"&&<h6>Use this coupon to enjoy special discount</h6>}


	</div>

	</div>
	<div className="d-flex justify-content-between create_join">
	<button className="w-50 create_group"><img src={CreateVector}/><>
	{couponsDetailsFormik.values.text_size===null&&<h6>Create a Group</h6>}

	{couponsDetailsFormik.values.text_size==="s"&&<p className="mb-0">Create a Group</p>}
	{couponsDetailsFormik.values.text_size==="m"&&<h6 className="mb-0">Create a Group</h6>}
	{couponsDetailsFormik.values.text_size==="l"&&<h5 className="mb-0">Create a Group</h5>}
	</>
	</button>
	<button className="w-50 join_group"><img src={JoinVector}/>
	{couponsDetailsFormik.values.text_size===null&&<h6>Join a Group</h6>}

	{couponsDetailsFormik.values.text_size==="s"&&<p className="mb-0">Join a Group</p>}
	{couponsDetailsFormik.values.text_size==="m"&&<h6 className="mb-0">Join a Group</h6>}
	{couponsDetailsFormik.values.text_size==="l"&&<h5 className="mb-0">Join a Group</h5>}
	</button>
	</div>
</div>}
		{Number(couponsDetailsFormik.values.couponType)===2&&<div style={couponsDetailsFormik.values.gradient_type==="horizontal"?{backgroundImage: `linear-gradient(to right, ${couponsDetailsFormik.values.gradient_from}, ${couponsDetailsFormik.values.gradient_to})`}:{backgroundImage: `linear-gradient(to bottom, ${couponsDetailsFormik.values.gradient_top}, ${couponsDetailsFormik.values.gradient_bottom})`}} className="coupon_cour d-flex justify-content-between ">
<div className={`${styles.inner_coupon} `}>
<img
src={BookVector}
height="40"
width="40"
alt="avatar"
/>
<h5 style={{color: couponsDetailsFormik.values.textColor}} className="mb-0 text-center">X KD/%</h5>
<h4 style={{color: couponsDetailsFormik.values.textColor}}>OFF</h4>
</div>
<div className={styles.days_Lefts}>
<h5 style={{color: couponsDetailsFormik.values.textColor}} className="mb-0">X Courses</h5>
<h3 style={{color: couponsDetailsFormik.values.textColor}}>X KD/% OFF</h3>
<span>
<label style={{color: couponsDetailsFormik.values.textColor}}>X/XX</label>
<progress id="file" value="32" max="100">
32%
</progress>
</span>
<p className="red_alert">Valid Till {couponsDetailsFormik.values.date}</p>
<button style={{backgroundColor: couponsDetailsFormik.values.buttonColor}}>View Available courses under this Offer</button>
</div>
</div>}
{Number(couponsDetailsFormik.values.couponType)===3&&<div style={couponsDetailsFormik.values.gradient_type==="horizontal"?{backgroundImage: `linear-gradient(to right, ${couponsDetailsFormik.values.gradient_from}, ${couponsDetailsFormik.values.gradient_to})`}:{backgroundImage: `linear-gradient(to bottom, ${couponsDetailsFormik.values.gradient_top}, ${couponsDetailsFormik.values.gradient_bottom})`}} className={styles.included_courses}>
<label className={`${styles.included_cart} d-flex justify-content-between`}>
<p>Included Courses:</p>
<p className="text-white">X courses</p>
</label>

<div className={styles.timeline_primins}>
<div className={styles.container}>
<div className={styles.content}>
<h5>
Course Name <span>X KD</span>
</h5>
<p>Department</p>
</div>
</div>
</div>
<div className={styles.timeline_primins}>
<div className={styles.container}>
<div className={styles.content}>
<h5>
Course Name <span>X KD</span>
</h5>
<p>Department</p>
</div>
</div>
</div>

<label className={`${styles.price_total_cart} d-flex align-items-center`}>
<h6>
<p className="mb-0">Total Price</p>
<h5 className="d-flex justify-content-between">
<h6>x KD </h6>
<span>x KD</span>
</h5>
</h6>
<button>Add to Cart</button>
<img src={offer_book} alt="" />
</label>
</div>}

{Number(couponsDetailsFormik.values.couponType)===3&&
<div style={couponsDetailsFormik.values.gradient_type==="horizontal"?{backgroundImage: `linear-gradient(to right, ${couponsDetailsFormik.values.gradient_from}, ${couponsDetailsFormik.values.gradient_to})`}:{backgroundImage: `linear-gradient(to bottom, ${couponsDetailsFormik.values.gradient_top}, ${couponsDetailsFormik.values.gradient_bottom})`}} className="courses_package">
	<div className="d-flex justify-content-between">
		<span>
			<h6>{couponsDetailsFormik.values.text}</h6>
			<p>{couponsDetailsFormik.values.description}</p>
			<button style={{backgroundColor: couponsDetailsFormik.values.buttonColor}}> View Courses</button>
		</span>
		<span className="time_hour d-flex">
			<p><label>38 </label> days</p>
			<p><label>8 </label>8 Hours</p>
			<p><label>42 </label> Mins</p>
		</span>
		{bannerImage&&<img src={bannerImage}/>}
	</div>
</div>}
					<Button onClick={()=>{couponsDetailsFormik.submitForm();
					}
						
					} className={`${styles.buttonss} mt-3`} type="submit">
						{selectedCoupon.editOrAdd === INSTRUCTOR.REDUX.ADD
							? "Add"
							: "Edit"}
					</Button>
				</Modal.Body>
			</div>
		</Modal>
	);
};

export default AddorEdit;
