import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Button, Dropdown, Form, InputGroup, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import commonStyles from "../../features/common/styles/common.module.css";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import {
  Avatar,
  CourseManagement,
  InstructorManagement,
  Logout,
  Profile,
  StudentManagement,
  WhiteLogo,
  Setting
} from "../../assets/images";
import { LayoutProps } from "./interface";
// import User from "../../assets/images/users.png"
import Toggle from "../../assets/images/toggle.png";
import styles from "./style.module.css";
import { APP_ROUTES } from "../../utils/routes";
import { ROUTE_CONSTANTS } from "../../routes/constants";
import storage from "../../utils/storage";
import { AUTH } from "../../utils/auth";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { changeTheme } from "../../redux/reducers/theme";
import { setSearch } from "../../redux/reducers/search";

const Layout = ({ children }: LayoutProps) => {
  const location = useLocation();
  const { id } = useParams();
  const authUser = useAppSelector((state) => state.authReducer);
  const dispatch = useAppDispatch();
  const [active, setActive] = useState(true);
  const [searchText, setSearchText] = useState("");

  const navigate = useNavigate();
  const handleLogout = () => {
    storage.clearToken();
    localStorage.removeItem(AUTH.REMEMBERME);
    navigate("/");
  };

  const [searchValue, setSearchValue] = useState<string>("");

  const handleChangeTheme = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      dispatch(
        changeTheme({
          theme: "dark",
        })
      );
    } else {
      dispatch(
        changeTheme({
          theme: "light",
        })
      );
    }
  };

  return (
    <>
      <div
        className={`light-side-bar ${
          active ? "active-sidebar" : "inactive-sidebar"
        }  ${styles["dashboard-page"]}`}
      >
        <div className={`light-side-bar ${styles["side-bar-left"]}`}>
          <div className={styles["menu-list"]}>
            <div className={styles["top-logo"]}>
              <img src={WhiteLogo} alt="logo" />
            </div>
            <span>Main</span>
            <ul>
              <li
                onClick={() => dispatch(setSearch({ text: "" }))}
                className={
                  location.pathname === APP_ROUTES.UNIVERSITY_LIST ||
                  location.pathname === `${APP_ROUTES.UNIVERSITY_LIST}/${id}`
                    ? `${styles.active} ${styles["cursor-pointer"]}`
                    : styles["cursor-pointer"]
                }
              >
                <a onClick={() => navigate(ROUTE_CONSTANTS.UNIVERSITIES)}>
                  <img src={StudentManagement} alt="course" />
                  University Management
                </a>
              </li>
              <li
                onClick={() => dispatch(setSearch({ text: "" }))}
                className={
                  location.pathname === APP_ROUTES.COUPONS_LIST ||
                  location.pathname === `${APP_ROUTES.COUPONS_LIST}/${id}`
                    ? `${styles.active} ${styles["cursor-pointer"]}`
                    : styles["cursor-pointer"]
                }
              >
                <a onClick={() => navigate(ROUTE_CONSTANTS.COUPONS)}>
                  <img src={StudentManagement} alt="course" />
                  Coupons Management
                </a>
              </li>
              <li
                onClick={() => dispatch(setSearch({ text: "" }))}
                className={
                  location.pathname === APP_ROUTES.INSTRUCTORS_LIST ||
                  location.pathname === APP_ROUTES.INSTRUCTORS_ADD ||
                  location.pathname ===
                    `${APP_ROUTES.INSTRUCTORS_LIST}/${id}` ||
                  location.pathname ===
                    `${ROUTE_CONSTANTS.INSTRUCTORS_CHAPTERS}/${id}` ||
                  location.pathname ===
                    `${ROUTE_CONSTANTS.INSTRUCTORS_COURSES}/${id}` ||
                  location.pathname ===
                    `${ROUTE_CONSTANTS.INSTRUCTOR_CHAPTERINFO}/${id}` ||
                  location.pathname ===
                    `${ROUTE_CONSTANTS.INSTRUCTORS_COURSES_STUDENTLIST}/${id}`
                    ? `${styles.active} ${styles["cursor-pointer"]}`
                    : styles["cursor-pointer"]
                }
              >
                <a onClick={() => navigate(ROUTE_CONSTANTS.INSTRUCTOR)}>
                  <img src={InstructorManagement} alt="instructor" />
                  Instructor Management
                </a>
              </li>

              <li
                onClick={() => dispatch(setSearch({ text: "" }))}
                className={
                  location.pathname === APP_ROUTES.STUDENTS_LIST ||
                  location.pathname ===
                    `${APP_ROUTES.STUDENTS_LIST}/edit/${id}` ||
                  location.pathname === APP_ROUTES.STUDENTS_ADD ||
                  location.pathname === `${APP_ROUTES.STUDENTS_LIST}/${id}`
                    ? `${styles.active} ${styles["cursor-pointer"]}`
                    : styles["cursor-pointer"]
                }
              >
                <a onClick={() => navigate(ROUTE_CONSTANTS.STUDENTS)}>
                  <img src={StudentManagement} alt="student" />
                  Student Management
                </a>
              </li>
              <li
                className={
                  location.pathname === APP_ROUTES.COURSES_LIST ||
                  location.pathname === `${APP_ROUTES.COURSES_LIST}/${id}`
                    ? `${styles.active} ${styles["cursor-pointer"]}`
                    : styles["cursor-pointer"]
                }
              >
                <a onClick={() => navigate(ROUTE_CONSTANTS.COURSES)}>
                  <img src={CourseManagement} alt="course" />
                  Course Management
                </a>
              </li>
              <li
                onClick={() => dispatch(setSearch({ text: "" }))}
                className={
                  location.pathname === APP_ROUTES.INVOICE_LIST
                    ? // 				||
                      // location.pathname === `${APP_ROUTES.COURSES_LIST}/${id}`
                      `${styles.active} ${styles["cursor-pointer"]}`
                    : styles["cursor-pointer"]
                }
              >
                <a onClick={() => navigate(ROUTE_CONSTANTS.INVOICE)}>
                  <img src={CourseManagement} alt="course" />
                  Invoice Management
                </a>
              </li>
              
              {/* <li
                onClick={() => dispatch(setSearch({ text: "" }))}
                className={
                  location.pathname === "/configurations"
                    ? `${styles.active} ${styles["cursor-pointer"]}`
                    : styles["cursor-pointer"]
                }
              >
                <a onClick={() => navigate("/configurations")}>
                  <img src={Setting} alt="course" />
                  Configurations
                </a>
              </li> */}
              
              <li
                onClick={() => dispatch(setSearch({ text: "" }))}
                className={
                  location.pathname === APP_ROUTES.PROFILE
                    ? `${styles.active} ${styles["cursor-pointer"]}`
                    : styles["cursor-pointer"]
                }
              >
                <a onClick={() => navigate(ROUTE_CONSTANTS.PROFILE)}>
                  <img src={Profile} alt="profile" />
                  Profile
                </a>
              </li>
              <li
                onClick={() => dispatch(setSearch({ text: "" }))}
                className={
                  location.pathname === "/transactions"
                    ? `${styles.active} ${styles["cursor-pointer"]}`
                    : styles["cursor-pointer"]
                }
              >
                <a onClick={() => navigate("/transactions")}>
                  <img src={Profile} alt="profile" />
                  Transactions
                </a>
              </li>
              {/* <li>
							<a href="/">
								<img src={Notification} alt="notification" />
                Notifications
							</a>
						</li> */}
              <li
                onClick={handleLogout}
                className={`logout ${styles["cursor-pointer"]}`}
              >
                <a onClick={() => dispatch(setSearch({ text: "" }))}>
                  <img src={Logout} alt="logout" />
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className={`right-side-bar ${styles["side-bar-right"]}`}>
          <div className={`darktopbar ${styles["top-bar"]}`}>
            <div className="toggle_icon" onClick={() => setActive(!active)}>
              <img src={Toggle} alt="" />
            </div>

            <InputGroup className={`${commonStyles["search-bar"]}`}>
              <Form.Control
                placeholder="Search Name"
                type="text"
                aria-label="Search"
                aria-describedby="basic-addon2"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />
              <Button
                onClick={() => {
                  dispatch(setSearch({ text: searchText }));
                }}
                variant="outline-secondary"
                id="button-addon2"
              >
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            </InputGroup>

            <div className="switch-theme">
              <div className="form-check form-switch">
                <input
                  onChange={handleChangeTheme}
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckDefault"
                />
                <label
                  className="form-check-label"
                  htmlFor="flexSwitchCheckDefault"
                >
                  Dark Mode
                </label>
              </div>
            </div>

            {/* <div className={styles["ham-brhr"]}>
					   <span>
						   <img src={ThreeLine} alt="three-line" />
					   </span>
				   </div> */}

            <div className={styles["notification-bar"]}>
              {/* <div className={styles["notify"]}>
						   <img src={NotificationBell} alt="notification-bell" />
						   <span>2</span>
					   </div> */}
              <div
                onClick={() => navigate(ROUTE_CONSTANTS.PROFILE)}
                className={`${styles["agent-name"]} ${styles["cursor-pointer"]}`}
              >
                <span>
                  {/* <img
							   src={authUser.avatar ? authUser.avatar : Avatar}
							   height={30}
							   width={30}
							   alt="avatar"
						   /> */}

                  <img
                    src={authUser.avatar ? authUser.avatar : Avatar}
                    height={30}
                    width={30}
                    alt="avatar"
                  />
                </span>

                <div className={styles.nnmelist}>
                  <h5>Admin</h5>
                </div>
              </div>
            </div>
          </div>

          {children}
        </div>
      </div>
    </>
  );
};

export default Layout;
